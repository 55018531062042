// BuyThisDomain.js
import React from 'react';
import './BuyThisDomain.css'; // Puedes crear este archivo para estilos específicos

const BuyThisDomain = () => {
  return (
    <div className="buy-this-domain">
      <h1>Buy This Domain</h1>
      <p>This domain is for sale. Please contact us for more information.</p>
      <a href="mailto:addabidomines@gmail.com" className="buy-this-domain-link">
        Contact Us
      </a>
    </div>
  );
};

export default BuyThisDomain;
