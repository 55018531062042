// App.js
import React from 'react';
import logo from './logo.svg';
import './App.css';
import BuyThisDomain from './BuyThisDomain'; // Importa el nuevo componente

function App() {
  return (
    <div className="App">
     
      <main>
        <BuyThisDomain /> {/* Añade el componente BuyThisDomain aquí */}
      </main>
    </div>
  );
}

export default App;
